import React, { useState, useEffect } from "react";
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import teamlogo from "../footerlogo.svg";
import arrow from "../matcharrow.svg";
import arrow1 from "../arrow.svg";
import { Helmet } from "react-helmet";

function Matches() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";

  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/FIXTURES?fields=["*"]&limit_page_length=100'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Sort by position field
        const sortedData = data.data.sort((a, b) => a.match_number - b.match_number);

        setMatches(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  // Group matches by match_date
  const groupMatchesByDate = (matches) => {
    return matches.reduce((acc, match) => {
      const matchDate = new Date(match.match_date).toDateString();
      if (!acc[matchDate]) {
        acc[matchDate] = [];
      }
      acc[matchDate].push(match);
      return acc;
    }, {});
  };

  const groupedMatches = groupMatchesByDate(matches);

  return (
    <>
      <Helmet>
        <title> UPKL Matches - Upcoming Kabaddi Game Schedule</title>
        <meta name="title" content=" UPKL Matches - Upcoming Kabaddi Game Schedule"/>
        <meta name="description" content="Check out the Uttar Pradesh Kabaddi League (UPKL) Matches page for the latest schedule of exciting Kabaddi games. Stay informed about upcoming matches and support your favorite local Kabaddi teams."/>
        <link rel="canonical" href="http://upkl.in/matches" />
      </Helmet>
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">MATCHES</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" alt="Arrow" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="matchstrip">
          <div className="container-fluid">
            {loading ? (
              <p className="text-white">Loading...</p>
            ) : (
              Object.keys(groupedMatches).map((date) => (
                <div key={date}>
                  <div className="titlecaption">
                    <h2 className="matchhead">{date}</h2>
                    <div className="linetilte"></div>
                  </div>
                  {groupedMatches[date].map((match, index) => (
                    <div className="matchesbox" key={match.name}>
                      <div className="row">
                        <div className="col-md-1 col-12 align-self-center">
                          <div className="matchleft">
                            <h5 className="finalmatch">
                              MATCH {match.match_day}
                             
                              {/* <span className="rightarrowmatch desktopnone"> */}
                                {/* <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 29 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                                    fill="white"
                                  />
                                </svg> */}
                              {/* </span> */}
                            </h5>
                          </div>
                          <div className="matchseparator desktopnone"></div>
                        </div>
                        <div className="col-md-8 col-12">
                          <div className="matchcenter">
                            <div className="row">
                              <div className="col-md-2 col-2 align-self-center">
                                <img  src={`https://dev-upkl.upkl.in/${match.team_a_logo}`} className="float-right img-fluid" alt="Team Logo" />
                              </div>
                              <div className="col-3 align-self-center">
                                <div className="teamname">
                                  <h6>{match.team_a_name}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 col-2 p-0 p-sm-0 align-self-center">
                                <div className="match-score-tl">
                                  {/* <h5>--</h5> */}
                                </div>
                                <div className="match-score">
                                  <h5>VS</h5>
                                </div>
                              </div>
                              <div className="col-3 align-self-center">
                                <div className="teamname">
                                  <h6>{match.team_b_name}</h6>
                                </div>
                              </div>
                              <div className="col-md-2 col-2 align-self-center">
                                <img  src={`https://dev-upkl.upkl.in/${match.team_b_logo}`} className="float-left img-fluid" alt="Team Logo" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-12 align-self-center">
                          <div className="row">
                            <div className="col-md-8 col-12 p-0">
                              <div className="matchright">
                                <p>Noida Indoor Stadium, Noida <br></br><b>(Time : {match.match_time} )</b></p>
                              </div>
                            </div>
                            <div className="col-4 d-none d-md-block d-lg-block">
                              <center>
                                <div className="matchlogotop">
                                  <div className="matchlogo">
                                    <img src={teamlogo} alt="Match Logo" />
                                  </div>
                                </div>
                              </center>
                              <img src={arrow} className="img-fluid matcharrow" alt="Arrow" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Matches;
