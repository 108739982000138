// React Component
import React ,{useState} from 'react';
import '../src/App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Team from './Team/Team'
import Home from '../src/Components/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Matches from './Matches/Matches';
import About from './About/About';
import Standing from './Standing/Standing';
import News from './New Page/New';
import Auction from './Auction/Auction';

const App = () => {
  return (
    <>

    
<BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/matches" element={<Matches/>} />
        <Route path="/standing" element={<Standing/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/auction" element={<Auction/>} />





        
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
