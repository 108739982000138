import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../Include/Nav";
import arrow from "../arrow.svg";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import tv from "../teambg.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import breadcumb from '../breadcrumb-background-yamuna (1).jpg'

import Footer from "../Include/Footer1";

export default function New() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/Print%20Coverage?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3.9,
      },
    },
  };

  return (
    <>
     
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">NEWS</h2>
              <div className="linetilte"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="newpage">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">News</h2>
              <div className="linetilte mb-2"></div>
            </div>
          </div>
        </div>
        {loading ? (
                      <SkeletonTheme baseColor="#81290a" highlightColor="#9a3d1c">
                        <Skeleton width={260} height={400} />{" "}
                        </SkeletonTheme>
        ) : (
            <div  className="container-fluid">
              <div className="row">
              {standings.length > 0 ? (
              standings.map((team, index) => (
                <div className="col-md-3 col-12">

                <div className="card" key={index}>
                  <img
                    src="/static/media/borderblog.21867030a71636afa32cfd31be23043a.svg"
                    className="img-fluid bordernew"
                    alt="Border"
                  />
                  <img
                    className="card-img-top"
                    src={`https://dev-upkl.upkl.in/${team.image}`}
                    alt="Card image cap"
                  />
                </div>
           

                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
              </div>
            </div>
            
        )}
      </section>
      <Footer />
    </>
  );
}
