// React Component
import React, { useState, useEffect } from "react";
import "../App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";

import borderimg from "../boxborder.svg";
import "react-loading-skeleton/dist/skeleton.css";
import StandingsTable from "./StandingsTable";
import New from "./New";
import Banner from "./Banner";
import { Helmet } from "react-helmet";
import Fixture from "./Fixture";
import aboutimg from "./knight12.png";
import Auctionimg from "../Auction/27-june-auction-thumbnail (1).jpg";
const Home = () => {
  const [standings, setStandings] = useState([]);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          "https://dev-upkl.upkl.in/api/resource/Standings?fields=[%22*%22]",
          {
            headers: {
              Cookie:
                "full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image=",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch standings");
        }
        const data = await response.json();
        setStandings(data);
      } catch (error) {
        console.error("Error fetching standings:", error);
      }
    };

    fetchStandings();
  }, []);

  // const Banner = "https://dev-upkl.upkl.in/files/Rectangle 2.jpg";

  const playerbanner = "https://dev-upkl.upkl.in/files/Rectangle 26 (1).png";

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSliderChange = (event) => {
    setCurrentIndex(event.item.index);
  };

  const renderContent = (index) => {
    switch (index) {
      case 0:
        return (
          <div>
            <h2>Content Title 1</h2>
            <p>Content for Slide 1</p>
          </div>
        );
      case 1:
        return (
          <div>
            <h2>Content Title 2</h2>
            <p>Content for Slide 2</p>
          </div>
        );
      // Add more cases as needed
      default:
        return null;
    }
  };

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    loop: true,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds (4 seconds)
    responsive: {
      0: {
        items: 1.4,
        margin: 5,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4.5,
      },
    },
  };

  const options2 = {
    loop: true,
    margin: 20,
    nav: false,
    loop: true,
    autoplay: true, // Enable autoplay

    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3.5,
      },
    },
  };

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    loop: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const options4 = {
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    autoplayTimeout: 4200,
    autoplayHoverPause: false,
    animateOut: "fadeOut", // Fade out animation
    animateIn: "fadeIn", // Fade in animation
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const options5 = {
    loop: true,
    margin: 20,
    nav: false,
    loop: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4500,
    autoplayHoverPause: false,
    animateOut: "fadeOut", // Fade out animation
    animateIn: "fadeIn", // Fade in animation
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <Nav />
      <Banner />

      <section className="aboutsection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="whoweareleft">
                <img src={aboutimg} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="titlecaption">
                <div className="d-flex">
                  <div className="newarrows">
                    <h2 className="text-white"> About Us </h2>
                    <div className="linetilte"></div>
                  </div>
                </div>
              </div>
              <div className="aboutuspara">
                <p>
                  The Yamuna Yodhas Kabaddi Team isn 't just a team; it' s a
                  beacon of hope, rallying communities to reclaim the essence of
                  their heritage and forge a brighter future. With a mix of
                  unstoppable energy, honed-out skills and steely nerves, here '
                  s a force that consistently looks forward to perform better,
                  challenge its opponents and make a difference. They mirrors
                  the determination of those fighting to preserve the purity of
                  the Yamuna River, making them champions both on and off the
                  field.
                </p>

                <button className="readmore">Read More</button>
              
              </div>
            </div>
          </div>
        </div>
      </section>

      <StandingsTable />

      <Fixture />

      {/* <div className='titlecaption'>
  <h2>PLAYER STATS</h2>
  <div className='linetilte'></div>
</div>
<OwlCarousel className="owl-theme" {...options1}>
  <div className="itemstats">
 <div className='playerstats'>
  <div className='topsection'>
   <div className='row'>
   <div className='col-6'>
    <img src={logo} className='img-fluid logoteam'/>
     <div className='playername'>
     <h5>VINAY<br></br>
PRADHAN</h5>
     </div>
    </div>
    <div className='col-6'>
      <img src={player} className='img-fluid  playerimg'/>
    </div>
   </div>

  </div>
  <div className='bottomsection'>
    <div className='row'>
      <div className='col-4'>
        <div className='stats'>
          <h5>MATCHES</h5>
          <h3>112</h3>
        </div>
      </div>
      <div className='col-2'>
      <div className='stats'>
          <h5>WIN</h5>
          <h3>87</h3>

        </div>
      </div>
      <div className='col-6'>
      <div className='stats'>
          <h5>HIGHEST SCORE</h5>
          <h3>67</h3>

        </div>
      </div>

    </div>
  </div>
 </div>

  </div>

</OwlCarousel> */}

      <section className="kabaddiman" id="rahul">
        <div class="overlay-content">
          <div className="row row1">
            <div className="col-md-5 col-12 offset-md-1 offset-0">
              <div className="newletterright">
                <h5>
                THE GREAT

KHALI: TEAM ICON
                </h5>
                <div className="line"></div>
                <p>
                The Great Khali, an iconic WWE wrestler, is renowned for his towering stature and powerful presence. His larger-than-life persona and memorable matches have solidified his place in wrestling history, captivating fans globally and inspiring a new generation of wrestlers.
                </p>

                {/* <div className='row'>
  <div className='newlogos'>
    <img src={team} className='img-fluid' />
    <div className='date'>19 May, 2024</div>
  </div>
</div> */}
              </div>
              <img src={borderimg} className="img-fluid borderimg" />
            </div>
            <div className="col-md-7 col-12"></div>

          </div>
        </div>
      </section>
      <New />
      <Footer />

    </>
  );
};

export default Home;
