// React Component
import React ,{useState} from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Nav from '../Include/Nav';
import Footer from '../Include/Footer1';
import 'react-loading-skeleton/dist/skeleton.css'
import arrow1 from '../arrow.svg'
import StandingsTable from '../Components/StandingsTable';
import { Helmet } from "react-helmet";
import breadcumb from '../breadcrumb-background-yamuna (1).jpg'


const Standing = () => {

  // const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  return (
   
   <>


<Nav/>



<section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">STANDINGS</h2>
              <div className="linetilte"></div>
            </div>
          </div>
        </div>
      </section>






<StandingsTable/>



<Footer/>
    </>
  );
}

export default Standing;
