import React from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import founder from "./founder-image-rahul-sharma.jpg";
import { Helmet } from "react-helmet";
import breadcumb from '../breadcrumb-background-yamuna (1).jpg'
export default function About() {
  return (
    <>
      
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">ABOUT US</h2>
              <div className="linetilte"></div>
            </div>
           
          </div>
        </div>
      </section>
      <section className="aboutsection">
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">About Warrior</h2>
                <div className="linetilte"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <div className="whoweareleft1">
                <img
                  src="https://t4.ftcdn.net/jpg/08/50/28/91/360_F_850289151_JTGyna7l5xiZ4Ck2uul47sEyr1WhcO0O.jpg"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                The Yamuna Yodhas Kabaddi Team isn 't just a team; it' s a beacon of hope, rallying communities to reclaim the essence of their heritage and forge a brighter future. With a mix of unstoppable energy, honed-out skills and steely nerves, here ' s a force that consistently looks forward to perform better, challenge its opponents and make a difference. They mirrors the determination of those fighting to preserve the purity of the Yamuna River, making them champions both on and off the field.


                </p>
                <p>
                  Beyond empowering these athletes, UPKL endeavors to captivate
                  a wide audience, ensuring the continued growth and
                  entertainment value of Kabaddi throughout the nation. With its
                  focus on talent development and audience engagement, UPKL
                  emerges as a vital force in revitalizing the spirit of Kabaddi
                  and solidifying its position as a beloved sport in India.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutseparate"></div>
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">MISSION VISION</h2>
                <div className="linetilte"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <div className="ourmissionbox">
                <div className="title">MISSION</div>
                <p>
                OUR MISSION IS TO RESTORE THE YAMUNA RIVER BY REDUCING
POLLUTION AND PROMOTING SUSTAINABLE PRACTICES, WHILE
ENSURING WOMEN'S SAFETY THROUGH ROBUST PROTECTION
MEASURES AND COMMUNITY SUPPORT. EMBRACING THE SPIRIT OF
KABADDI, WE AIM FOR A HEALTHIER ENVIRONMENT AND A SECURE
SOCIETY FOR ALL, FOSTERING TEAMWORK, STRENGTH, AND RESILIENCE.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="ourmissionbox">
                <div className="title">VISION</div>
                <p>
                OUR VISION IS TO PROMOTE RURAL SPORTS, WITH A SPECIAL EMPHASIS
ON KABADDI, TO RAISE AWARENESS AND GARNER SUPPORT TO
EMPOWER COMMUNITIES. WE AIM TO FOSTER TALENT, ENHANCE
EDUCATION, AND BUILD A STRONG SUPPORT SYSTEM, CONTRIBUTING TO
THE HOLISTIC DEVELOPMENT AND WELL-BEING OF RURAL AREAS.
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="videoplayer">
        <div class="custom-container">
          <div class="custom-background-image"></div>
          <div class="custom-overlay"></div>
          <div class="custom-content">
            <h1>
              WATCH THE AMAZING <br></br>TRAINING ATMOSPHERE<br></br>AT OUR CLUB
              WITH TALENT COACHES
              UPKL 2024 FOR DELCATRATION ALL BIGS SCC
            </h1>
          </div>
          <div class="custom-play-button">
            <img src={icon} className="img-fluid" />
          </div>
        </div>
      </section> */}

      <section className="aboutfounder">
        <div className="container-fluid">
        

          <div className="founderbox">
            <div className="row">
              <div className="col-md-5 col-12 align-self-center">
                <div className="founderright">
                  <div className="founderimg">
                    <img src={founder} className="img-fluid" />
                  </div>
                  <div className="foundermessage "></div>
                </div>
              </div>
              <div className="col-md-7 col-12 align-self-center">
                <div className="foundermessage foundermessage1">
                <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">FOUNDERS MESSAGE</h2>
              </div>
            </div>
          </div>
                  <p>
                  A Civil Engineer and Real Estate Professional holding a B.Tech degree, currently pursuing an LLB. With a commitment to environmental stewardship, he has dedicated two years to the Vrinda Foundation, actively involved in initiatives focused on Yamuna cleaning, awareness, and education. His multifaceted background blends technical expertise with legal aspirations, driven by a passion for sustainable development and community service. He seeks to leverage his diverse skill set to make meaningful contributions in both the engineering and legal fields, aiming to create a positive impact on society.
                  </p>
                 

                  <div className="founderline"></div>
                  <div className="designation">
                    <h5>RAHUL

SHARMA </h5>
                    <h6>YAMUNA YODHAS</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
